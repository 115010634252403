<template>
  <div class="mt-5">
    <v-data-table
      dense
      :headers="headers"
      :items="reportData"
      :loading="loadReport"
      loading-text="Loading data ... Please wait">
      <template class="tableListDataRight" 
        v-slot:[`item.numberOfSubscriber`]="{ item }">
        {{ item.numberOfSubscriber | toNumber }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ReportContactByMessagesMonthly',
  props: {
    reportData: {
      type: Array
    },
    loadReport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Number of push',
          align: 'center',
          sortable: true,
          value: 'numberOfPush'
        },
        {
          text: 'Number of subscriber',
          align: 'center',
          sortable: true,
          value: 'numberOfSubscriber'
        },
      ]
    }
  }
}
</script>